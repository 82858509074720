
// @group elements
export default {
  props: {
    tag: {
      type: String,
      default: 'button'
    },

    to: {
      type: String
    },

    variant: {
      type: String,
      default: 'primary'
    },

    variantType: {
      type: String,
      default: 'normal'
    },

    target: {
      type: String,
      default: null
    }
  },

  computed: {
    buttonClass() {
      switch (this.variant) {
        case 'primary':
          switch (this.variantType) {
            case 'normal':
              return 'inline-block rounded-full py-4 px-6 bg-palette-graydark hover:bg-palette-graydarkhover border border-palette-graydark hover:border-palette-graydarkhover text-white hover:text-white text-l1 uppercase font-bold text-center no-underline transition pointer-events-auto'
            case 'outline':
              return 'inline-block rounded-full py-4 px-6 bg-transparent hover:bg-transparent border border-palette-graydark hover:border-palette-graydarkhover text-palette-graydark hover:text-palette-graydarkhover text-l1 uppercase font-bold text-center no-underline transition pointer-events-auto'
            default:
              break
          }
          break
        case 'secondary':
          switch (this.variantType) {
            case 'normal':
              return 'inline-block py-4 a-underlined text-l1 uppercase font-bold pointer-events-auto'
            case 'arrow':
              return 'arrow inline-flex items-center py-4 a-underlined text-l1 uppercase font-bold pointer-events-auto'
            case 'arrow-down':
              return 'arrow-down inline-flex items-center py-4 a-underlined large-space-between text-l1 uppercase font-bold pointer-events-auto'
            default:
              break
          }
          break
        default:
          break
      }
      return null
    }
  }
}
