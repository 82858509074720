
// @group components

export default {
  name: 'ContactSectionComponent',
  props: ['data'],
  methods: {
    toggleOverlay() {
      this.$store.commit('ui/toggleOverlay', true)
      this.$store.commit('ui/popupDatasTitle', this.data.popup.title)
      this.$store.commit('ui/popupDatasDescription', this.data.popup.description)
      this.$store.commit('ui/popupLocation', this.data.popup.location)
      this.$store.commit('ui/popupDatasParkInfo', this.data.popup.parkInfo)
      this.$store.commit('ui/popupLabel', this.data.googleMap.label)
      this.$store.commit('ui/popupLink', this.data.googleMap.link)
    }
  },

  mounted() {
    console.log(this.$props);
  }
}
