
// @group components
import { directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// import 'swiper/swiper-bundle.css'

export default {
  name: 'SwiperComponent',
  props: ['data'],

  directives: {
    swiper: directive
  },

  data() {
    return {
      slideIndex: 0,
      total: 0,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        keyboard: {
          enabled: true
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      }
    }
  },

  methods: {
    onSlideChange() {
      this.slideIndex = this.images.realIndex
    }
  }
}
